import Rubens from "../assets/rubens.jpg";
import Thay from "../assets/thay.jpg";
import Gaga from "../assets/gaga.jpg";
import Vitor from "../assets/vitor.jpeg";
import Slider from "react-slick";

import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function Players() {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    centerMode: true,
    className: "center",
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="w-full py-16 p-4 font-IBM">
      <h1
        className="text-4xl font-bold underline decoration-[#131E3C] decoration-4 text-center pb-16"
        id="players"
      >
        {t("ourPlayers")}
      </h1>
      <div className="max-w-[1200px] mx-auto">
        <Slider {...settings}>
          <div className="w-full shadow-xl flex flex-col p-6 my-4 justify-items-center hover:scale-105 duration-300">
            <img
              src={Rubens}
              alt="Rubens Queiroz"
              className="rounded-full max-w-[160px] h-auto mx-auto my-4"
            />
            <h2 className="text-2xl underline decoration-[#131E3C] decoration-2 text-center">
              Rubens Queiroz
            </h2>
            <div className="italic text-base text-center py-4">
              <p>{t("rubensInfo1")}</p>
              <p>Sport Recife</p>
              <p>{t("brazil")}</p>
            </div>
          </div>
          <div className="w-full shadow-xl flex flex-col p-6 my-4 justify-items-center hover:scale-105 duration-300">
            <img
              src={Thay}
              alt="Thaywanna Santiago"
              className="rounded-full max-w-[160px] h-auto mx-auto my-4"
            />
            <h2 className="text-2xl underline decoration-[#131E3C] decoration-2 text-center">
              Thaywanna Santiago
            </h2>
            <div className="italic text-base text-center py-4">
              <p>{t("thayInfo1")}</p>
              <p>Sport Recife</p>
              <p>{t("brazil")}</p>
            </div>
          </div>
          <div className="w-full shadow-xl flex flex-col p-6 my-4 justify-items-center hover:scale-105 duration-300">
            <img
              src={Gaga}
              alt="Gabriel Sanchez"
              className="rounded-full max-w-[160px] h-auto mx-auto my-4"
            />
            <h2 className="text-2xl underline decoration-[#131E3C] decoration-2 text-center">
              Gabriel Sanchez
            </h2>
            <div className="italic text-base text-center py-4">
              <p>{t("gagaInfo1")}</p>
              <p>Sport Recife</p>
              <p>{t("brazil")}</p>
            </div>
          </div>
          <div className="w-full shadow-xl flex flex-col p-6 my-4 justify-items-center hover:scale-105 duration-300">
            <img
              src={Vitor}
              alt="Vitor Gomes"
              className="rounded-full max-w-[160px] h-auto mx-auto my-4"
            />
            <h2 className="text-2xl underline decoration-[#131E3C] decoration-2 text-center">
              Vitor Gomes
            </h2>
            <div className="italic text-base text-center py-4">
              <p>{t("vitorInfo1")}</p>
              <p>Guarulhos</p>
              <p>{t("brazil")}</p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
