import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

import BngLogo from "../assets/bng.svg";

import { useTranslation } from "react-i18next";

export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 flex justify-between gap-4 text-black font-IBM">
      <div>
        <img
          src={BngLogo}
          alt="Brazil Next Generation logomarca"
          className="w-[159px]"
        />
        <p className="py-2">
          <a href="mailto:team@brazilnextgeneration.com">
            team@brazilnextgeneration.com
          </a>
        </p>
        <p className="py-4 md:w-2/4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
          ullam quaerat porro doloribus distinctio tempora ad accusamus
          voluptatum blanditiis totam.
        </p>
        <div className="flex gap-4 md:w-[75%]  text-[#131E3C]">
          <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
        </div>
      </div>
      <div className="">
        <div className="w-full">
          <h6 className="font-medium mb-4 text-[#131E3C]">Links</h6>
          <ul>
            <li className=" hover:underline">
              <a href="#top">{t("home")}</a>
            </li>
            <li className=" hover:underline">
              <a href="#team">{t("ourTeam")}</a>
            </li>
            <li className=" hover:underline">
              <a href="#players">{t("ourPlayers")}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
