import { useTranslation } from "react-i18next";

import Gui from "../assets/gui.png";
import Isa from "../assets/isa.png";
import Renata from "../assets/renata.png";

export function Team() {
  const { t } = useTranslation();
  return (
    <div className="w-full pt-16 px-4">
      <div className="max-w-[1024px] mx-auto flex flex-col font-IBM ">
        <h1
          className="text-4xl font-bold underline decoration-[#131E3C] decoration-4 text-center"
          id="team"
        >
          {t("ourTeam")}
        </h1>
        <div className="py-4 flex flex-col md:flex-row gap-4 description">
          <img
            src={Gui}
            alt="Guilherme Santanna"
            className="max-w-[270px] mx-auto md:max-w-full md:h-auto hover:scale-105 duration-300"
          />
          <div className="mt-8 md:mt-11">
            <h3 className="text-2xl font-bold underline decoration-[#131E3C] decoration-4">
              Guilherme Santanna
            </h3>
            <h4 className="text-base py-1 italic">{t("gCity")}</h4>
            <p>
              {t("gDesc")} <br />
              {t("gPrizes")}
            </p>
          </div>
        </div>
        <div className="py-4 flex flex-col-reverse md:flex-row gap-8 description">
          <div className="mt-11">
            <h3 className="text-2xl font-bold underline decoration-[#131E3C] decoration-4">
              Isabelle Aguiar
            </h3>
            <h4 className="text-base py-1 italic">{t("iCity")}</h4>
            <p>
              {t("iDesc")}
              <br />
              {t("iPrizes")}
            </p>
          </div>
          <img
            src={Isa}
            alt="Isabelle Aguiar"
            className="max-w-[270px] mx-auto md:max-w-full md:h-auto hover:scale-105 duration-300"
          />
        </div>
        <div className="py-4 flex flex-col md:flex-row gap-4 description">
          <img
            src={Renata}
            alt="Renata Cruz"
            className="max-w-[270px] mx-auto md:max-w-full md:h-auto hover:scale-105 duration-300"
          />
          <div className="mt-8 md:mt-11">
            <h3 className="text-2xl font-bold underline decoration-[#131E3C] decoration-4">
              Renata Cruz
            </h3>
            <h4 className="text-base py-1 italic">{t("rCity")}</h4>
            <p>
              {t("rDesc")}
              <br />
              {t("rPrizes")}
            </p>
          </div>
        </div>
      </div>
      <svg
        className="ret5 hidden md:block md:absolute"
        width="373"
        height="373"
        viewBox="0 0 373 373"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="151.903"
          y="3.47473"
          width="263"
          height="263"
          transform="rotate(34.3584 151.903 3.47473)"
          stroke="#131E3C"
          stroke-width="5"
        />
      </svg>

      <svg
        className="ret6 hidden md:block md:absolute"
        width="373"
        height="373"
        viewBox="0 0 373 373"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="151.903"
          y="3.47473"
          width="263"
          height="263"
          transform="rotate(34.3584 151.903 3.47473)"
          stroke="#131E3C"
          stroke-width="5"
        />
      </svg>
      <svg
        className="ret7 hidden md:block md:absolute"
        width="373"
        height="373"
        viewBox="0 0 373 373"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="151.903"
          y="3.47473"
          width="263"
          height="263"
          transform="rotate(34.3584 151.903 3.47473)"
          stroke="#FA824D"
          stroke-width="5"
        />
      </svg>
    </div>
  );
}
