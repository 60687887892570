import { useTranslation } from "react-i18next";

import Ball from "../assets/ball.png";

export function Main() {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div
        className="max-w-[1240px] mx-auto grid my-32 md:py-1 md:grid-cols-2"
        id="top"
      >
        <div className="flex flex-col justify-center px-4 font-IBM ">
          <h1 className="text-4xl font-bold underline decoration-[#131E3C] decoration-4">
            {t("main1")}
          </h1>
          <h1 className="text-4xl font-bold ">
            {t("main2")}{" "}
            <span className="underline decoration-[#131E3C] decoration-4">
              {t("main3")}
            </span>
          </h1>
          <a
            href="http"
            className=" mt-4 p-2 font-bold text-xl text-white text-center w-[200px] bg-[#131E3C] shadow hover:brightness-[0.9] hover:shadow-lg duration-100 block"
          >
            {t("mainButton")}
          </a>
        </div>
        <img
          src={Ball}
          alt="Imagem de uma bola de handeboll parada com uma trave no fundo."
          className="hidden max-w-[500px] mx-auto md:block relative z-2"
        />
        <svg
          className="ret1 hidden md:block md:absolute"
          width="373"
          height="373"
          viewBox="0 0 373 373"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="151.903"
            y="3.47473"
            width="263"
            height="263"
            transform="rotate(34.3584 151.903 3.47473)"
            stroke="#131E3C"
            stroke-width="5"
          />
        </svg>
        <svg
          className="ret2 hidden md:block md:absolute"
          width="373"
          height="373"
          viewBox="0 0 373 373"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="151.903"
            y="3.47473"
            width="263"
            height="263"
            transform="rotate(34.3584 151.903 3.47473)"
            stroke="#131E3C"
            stroke-width="5"
          />
        </svg>
        <svg
          className="ret3 hidden md:block md:absolute"
          width="373"
          height="373"
          viewBox="0 0 373 373"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="151.903"
            y="3.47473"
            width="263"
            height="263"
            transform="rotate(34.3584 151.903 3.47473)"
            stroke="#FA824D"
            stroke-width="5"
          />
        </svg>
        <svg
          className="ret4 hidden md:block md:absolute"
          width="373"
          height="373"
          viewBox="0 0 373 373"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="151.903"
            y="3.47473"
            width="263"
            height="263"
            transform="rotate(34.3584 151.903 3.47473)"
            stroke="#FA824D"
            stroke-width="5"
          />
        </svg>
      </div>
    </div>
  );
}
