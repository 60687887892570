import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);

    emailjs
      .sendForm(
        "service_xfx1af7",
        "template_2eqjkbn",
        form.current,
        "X0ZE_BdMe5ItgKv74"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Sua mensagem foi enviada com sucesso, obrigado!");
        },
        (error) => {
          console.log(error.text);
          alert("Algo deu errado, favor tentar novamente.");
        }
      );
    e.target.reset();
  };
  const { t } = useTranslation();
  return (
    <div
      className="w-full py-16 px-4 bg-[#131E3C] font-IBM text-white"
      id="contact"
    >
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
        <div className="lg:col-span-2 my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("contact1")}
          </h1>
          <p>{t("contact2")}</p>
        </div>
        <div className="my-2">
          <div className="flex flex-col items-center justify-center w-full ">
            <form ref={form} onSubmit={sendEmail} className="w-full">
              <input
                name="user_email"
                type="email"
                className="p-3 flex w-full text-black "
                placeholder={t("formName")}
                required
              />
              <textarea
                name="message"
                className="p-3 mt-2 w-full text-black"
                placeholder={t("formMessage")}
                required
                minLength={1}
              ></textarea>
              <button className="bg-[#FA824D] text-white w-full font-medium mt-2  mx-auto py-3">
                {t("formButton")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
