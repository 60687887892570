import { Main } from "./components/Main";
import { Navbar } from "./components/Navbar";
import { Team } from "./components/Team";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Players } from "./components/Players";

function App() {
  return (
    <>
      <Navbar />
      <Main />
      <Team />
      <Players />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
