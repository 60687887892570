import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "pt",
  lgn: "pt",
  resources: {
    pt: {
      translations: require("./locales/pt/translations.json"),
    },
    en: {
      translations: require("./locales/en/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNs: ["translations"],
});

i18n.languages = ["pt", "en"];

export default i18n;
