import { useState } from "react";
import { useTranslation } from "react-i18next";

import BngLogo from "../assets/bng.svg";
import Brazil from "../assets/br.png";
import UK from "../assets/uk.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";

export function Navbar() {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  const [language, setLanguage] = useState(false);
  const { i18n, t } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
    setLanguage(!language);
  }

  return (
    <div className="sticky top-0 bg-white z-40">
      <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 ">
        <a href="/">
          <img
            src={BngLogo}
            alt="Brazil Next Generation logomarca"
            className="w-[159px]"
          />
        </a>
        <div className="flex">
          <ul className="hidden md:flex font-IBM font-semibold text-1xl ">
            <li className="ml-[25px] hover-underline-animation">
              <a href="#top">{t("home")}</a>
            </li>
            <li className="ml-[25px] hover-underline-animation">
              <a href="#team">{t("ourTeam")}</a>
            </li>
            <li className="ml-[25px] hover-underline-animation">
              <a href="#players">{t("ourPlayers")}</a>
            </li>
            <li className="ml-[25px] w-[100px] h-[26px] font-bold text-center text-white bg-[#131E3C] shadow hover:brightness-[0.9] hover:shadow-lg duration-100">
              <a href="#contact">{t("contact")}</a>
            </li>
          </ul>
          <AiFillFacebook
            size={30}
            color={"#131E3C"}
            className="ml-[100px] hidden md:flex"
          />
          <AiFillInstagram
            size={30}
            color={"#131E3C"}
            className="ml-[25px] hidden md:flex"
          />
          <div className="flex ml-8 font-IBM text-1xl hidden md:flex align-baseline">
            <input
              type="image"
              src={UK}
              onClick={changeLanguage}
              value="en"
              alt=""
              className="max-w-[27px]"
            />

            <span className="w-[1rem]"></span>
            <input
              type="image"
              src={Brazil}
              onClick={changeLanguage}
              value="pt"
              alt=""
              className="max-w-[28px]"
            />
          </div>
          <div className="block md:hidden" onClick={handleNav}>
            {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
          </div>
          <div
            className={
              nav
                ? "fixed left-0 top-0 w-[60%] py-8 px-2 bg-[#fafefd] h-full ease-in-out duration-500"
                : "fixed left-[-100%]"
            }
          >
            <img
              src={BngLogo}
              alt="Brazil Next Generation logomarca"
              className="w-[159px] "
            />
            <ul className="font-IBM font-semibold text-1xl">
              <li className="py-2 px-2 my-3 hover:underline underline-offset-4 decoration-2 decoration-[#131E3C]">
                <a href="#top">{t("home")}</a>
              </li>
              <li className="py-2 px-2 my-3 hover:underline underline-offset-4 decoration-2 decoration-[#131E3C]">
                <a href="#team">{t("ourTeam")}</a>
              </li>
              <li className="py-2 px-2 my-3 hover:underline underline-offset-4 decoration-2 decoration-[#131E3C]">
                <a href="#players">{t("ourPlayers")}</a>
              </li>
              <li className="py-2 px-2 my-3 w-[100px] h-]26px] font-bold text-center text-white bg-[#131E3C]  shadow hover:brightness-[0.9] hover:shadow-lg duration-100">
                <a href="#contact">{t("contact")}</a>
              </li>
            </ul>
            <div className="flex mt-5">
              <AiFillFacebook size={30} color={"#131E3C"} />
              <AiFillInstagram size={30} color={"#131E3C"} />
            </div>
            <div className="flex mt-5 font-IBM text-1xl align-baseline">
              <input
                type="image"
                src={UK}
                onClick={changeLanguage}
                value="en"
                alt=""
                className="max-w-[27px]"
              />
              <span className="w-[.5rem]"></span>
              <input
                type="image"
                src={Brazil}
                onClick={changeLanguage}
                value="pt"
                alt=""
                className="max-w-[27px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
